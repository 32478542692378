<template>
  <div id="app">
   <header-nav></header-nav>
    <router-view/>
  </div>
</template>
<script>
import HeaderNav from  "@/components/Header"
export default {
  components:{
HeaderNav
  }
}
</script>
<style lang="scss">
@font-face {
  font-family: basicFont;
  src: url('./assets/font/ShareTechMono-Regular.ttf');
}
@font-face {
  font-family: colorFont;
  src: url('./assets/font/VT323-Regular.ttf');
}
a,a:link,a:visited,a:hover,a:active{
    text-decoration: none;
    color:inherit;
}
body{
  margin: unset;
  font-family: "basicFont";
}
#app{
  background-color: #3F2A5B;
  color: white;
}

</style>
