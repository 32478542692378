<template>
  <div class="header-nav">
    <div class="container">
      <div class="logo">
        <img src="../assets/img/logo.png" alt="" />
      </div>
      <div class="right">
        <div class="text-btn">Docs</div>
        <a href="#about"><div class="text-btn">About</div></a>
        <a href="#roadmap"><div class="text-btn">Roadmap</div></a>
        <a href="#team"><div class="text-btn">Team</div></a>
        <a href="https://twitter.com/MetaphoXanadu" class="icon"
          ><img src="../assets/img/twitter.svg" alt=""
        /></a>
        <!-- <a href="#" class="icon"
          ><img src="../assets/img/instagram.svg" alt=""
        /></a> -->
        <a href="http://discord.gg/mGdKGDTXKC" class="icon"
          ><img src="../assets/img/discord.svg" alt=""
        /></a>

        <div class="connect-wallet err-network">Comming Soon</div>
        <!-- <div class="connect-walle ">0.0035 ETH <div class="wallet-address">0xb0eE...4885</div></div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.header-nav {
  background-color: #292a2c;
  position: fixed;
  width: 100%;
  min-width: 1152px;
  .container {
    width: 1152px;
    margin: 0px auto;
    padding: 10px 0px;
    display: flex;
    align-items: center;
  }
  .logo {
    img {
      width: 200px;
    }
  }
  .right {
    margin-left: auto;
    display: flex;
    align-items: center;
    .icon {
      img {
        width: 35px;
        margin-right: 20px;
      }
    }
    .text-btn {
      margin-right: 20px;
      font-weight: bold;
      font-size: 23px;
      cursor: pointer;
    }
    .connect-wallet {
      background-image: linear-gradient(75deg, #f7f0ac, #acf7f0 50%, #f0acf7);
      color: black;
      height: 52px;
      line-height: 52px;
      width: 200px;
      text-align: center;
      border-radius: 5px;
      font-weight: bold;
      margin-left: 20px;
    }
  }
}
@media (max-width: 520px) {
  .header-nav {
    width: 100%;
    min-width: unset;
    .container{
        padding: 0px 10px;
        box-sizing: border-box;
    }
.text-btn,.icon{
    display: none;
}
.right{
    .connect-wallet{
        width:150px;
    }
}
    .container {
      width: 100% !important;
    }
  }
}
</style>
